<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <van-nav-bar :title="$t('success.title')" left-arrow @click-left="returnpev" />
            <div class="orderinfos">
              <p>{{ $t("success.orderamount") }}</p>
              <h1>{{ $t("currey") }}{{ order.total }}</h1>
              <p>{{ $t("success.goodsname") }}{{ order.title }}</p>
              <p>{{ $t("success.buyvalue") }}{{ order.goodstotal }} ML</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Starter page component
 */
export default {
  data() {
    return {
      loading: true,
      paymentloading: false,
      payway: [],
      order: {
        total: 0.0,
      },
      radio: "",
    };
  },
  mounted() {
    this.getpaymentstatus();
  },
  methods: {
    getpaymentstatus() {
      var that = this;
      that.$axios
        .post(that.apiuri, {
          action: "getorderinfo",
          ordersn: that.$route.params.id,
        })
        .then(function (response) {
          that.loading = false;
          that.order = response.data.data;
        });
    },
    returnpev() {
      this.$router.push({
        name: "home",
      });
    },
  },
};
</script>
<style>
.card-body {
  padding: 0;
}
.van-nav-bar .van-icon {
  color: #000;
}
.bottom_logo {
  bottom: 3rem;
}
.orderinfos {
  text-align: center;
  padding-top: 8rem;
}
</style>
